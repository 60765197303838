class LanguageManager {
	constructor(languages, dictionary, defaultLang) {
		this.languages = languages;
		this.dictionary = dictionary;
		this.lang = localStorage.getItem('lang') ?? defaultLang;
	}

	getLanguages() {
		return Object.keys(this.languages);
	}

	getLanguage() {
		return this.lang;
	}

	setLanguage(lang) {
		if (!(lang in this.languages)) {
			throw new Error(this.get('change_language_error'));
		}

		this.lang = lang;

		localStorage.setItem('lang', lang);
	}

	getDirection() {
		return this.languages[this.lang].direction;
	}

	obtain(stringKey, defaultValue = '??') {
		if (stringKey in this.dictionary && this.lang in this.dictionary[stringKey]) {
			return this.dictionary[stringKey][this.lang];
		}

		return defaultValue;
	}

	get(stringKey) {
		return this.obtain(stringKey, stringKey);
	}

	getDisplay(lang) {
		if (!(lang in this.languages)) {
			return '??';
		}

		return this.languages[lang].display;
	}

}

export default LanguageManager;