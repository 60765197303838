import React from 'react';
import styled from 'styled-components';

import ErrorMessage from '../error-message/ErrorMessage.js';
import { LanguageManager, ItemsController } from '../../app';
import { Fragment } from 'react';

const ActionBar = styled.div`
	background-color: var(--a-color);
	padding-bottom: 2pt;
	position: sticky;
	top: 0;
	width: 100%;
	display: grid;
	grid-template-columns: 50% 25% 25%;
`;

const ActionItem = styled.div`
	padding: 1pt;
	width: 100%;
`;

class AddItem extends React.Component {
	constructor(props) {
		super(props);

		this.state = this.getInitState();
	}

	getInitState() {
		return {
			error: null,
			itemTitle: '',
			itemAmount: ''
		}
	}

	handleAddClick(event) {
		try {
			ItemsController.addItem(this.state.itemTitle, this.state.itemAmount);
			this.setState(this.getInitState());
			ItemsController.clearFilter();
			if (this.nameInput) { this.nameInput.focus(); }
		}
		catch (error) {
			this.setState({ error: error.message });
		}
	}

	updateInputValue(event) {
		const inputName = event.target.name;
		const userInput = event.target.value;

		this.setState({
			[inputName]: userInput
		});

		if (inputName === 'itemTitle') {
			if ((userInput.length > 0)) {
				ItemsController.filterItems(userInput);
			}
			else {
				ItemsController.clearFilter();
			}
		}
	}

	onItemTitleFocus(event) {
		this.setState({ itemTitle: '' });
		ItemsController.clearFilter();
	}

	render() {
		return <Fragment>
			<ActionBar>
				<ActionItem>
					<label className="float-label">
						<input name="itemTitle" type="text" ref={(input) => { this.nameInput = input; }} value={this.state.itemTitle} onChange={event => this.updateInputValue(event)} placeholder={LanguageManager.obtain('item_title_label')} autoComplete="off" onFocus={event => this.onItemTitleFocus(event)} />
						<span>{LanguageManager.obtain('item_title_label')}</span>
					</label>
				</ActionItem>
				<ActionItem>
					<input name="itemAmount" value={this.state.itemAmount} onChange={event => this.updateInputValue(event)} type="number" autoComplete="off" pattern="[0-9]*" placeholder='1' />
				</ActionItem>
				<ActionItem>
					<input type="button" defaultValue={LanguageManager.obtain('item_add_button_label')} onClick={event => this.handleAddClick(event)} />
				</ActionItem>
			</ActionBar>
			{
				this.state.error &&
				<ErrorMessage message={LanguageManager.get(this.state.error)} />
			}
		</Fragment>;
	}
}

export default AddItem;