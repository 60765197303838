import Controller from '../../core/Controller';
import ItemStatus from './ItemStatus';

class ItemsController extends Controller {
	constructor() {
		super();

		this.nextId = localStorage.getItem('items-next-id') ?? 1;
		this.data = JSON.parse(localStorage.getItem('items-data')) ?? [];
		this.filterActive = false;
	}

	notifyAllData() {
		this.observersManager.notify({ data: this.getItems() });
	}

	saveDataAndNotify() {
		localStorage.setItem('items-data', JSON.stringify(this.data));

		this.notifyAllData();
	}

	getNextId() {
		const id = this.nextId++;

		localStorage.setItem('items-next-id', this.nextId);

		return id;
	}

	getItems() {
		return (this.isFilterActive()) ? this.filteredData : this.data;
	}

	getItemsByStatus(status) {
		if (!(status instanceof ItemStatus)) {
			throw new Error('invalid-item-status');
		}

		return this.data.filter((item) => item.status === status.value);
	}

	getItemsByTitle(title) {
		return this.data.filter((item) => item.title.toLowerCase().indexOf(title.toLowerCase()) > -1);
	}

	getItem(id) {
		return this.data.find(item => item.id === id);
	}

	getItemByTitle(title) {
		return this.data.find(item => item.title === title);
	}

	isFilterActive() {
		return this.filterActive;
	}

	clearFilter() {
		this.filterActive = false;
		this.filteredData = [];

		this.notifyAllData();
	}

	filterItems(title) {
		this.filterActive = true;
		this.filteredData = this.getItemsByTitle(title);

		this.notifyAllData();
	}

	addItem(title, amount) {
		title = title.trim();
		if (title === '') {
			throw new Error('item_missing_title');
		}

		amount = parseInt(amount);
		amount = (isNaN(amount)) ? 1 : amount;

		if (this.updateItemByTitle(title, { amount, status: ItemStatus.Needed.value }, true)) {
			return;
		}

		if (amount < 1) {
			throw new Error('amount_lower_then_one');
		}

		this.data.push({
			id: this.getNextId(),
			status: ItemStatus.Needed.value,
			amount,
			title
		});

		this.saveDataAndNotify();
	}

	updateItemByTitle(title, { amount, status }, titleNotFoundSafe) {
		const item = this.getItemByTitle(title);
		if (!item) {
			if (titleNotFoundSafe) {
				return false;
			}

			throw new Error('update_by_title_item_not_found');
		}

		if (amount < 1) {
			this.deleteItem(item);
			return true;
		}

		item.status = status;
		item.amount = amount;

		this.saveDataAndNotify();

		return true;
	}

	acquireCollectedItems() {
		let items = this.getItemsByStatus(ItemStatus.Collected);

		items.map(item => item.status = ItemStatus.Acquired.value);

		this.saveDataAndNotify();
	}

	deleteItem(item) {
		const index = this.data.indexOf(item);

		if (index === -1) {
			throw new Error('delete_item_not_found');
		}

		this.data.splice(index, 1);

		this.saveDataAndNotify();
	}

	invertItemStatus(id) {
		const item = this.getItem(id);

		item.status = this.getInvertedStatus(item.status).value;

		this.saveDataAndNotify();
	}

	getInvertedStatus(status) {
		return (ItemStatus.Needed.equals(status)) ? ItemStatus.Collected : ItemStatus.Needed;
	}
}

export default new ItemsController();