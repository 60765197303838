class ItemStatus {
	static Needed = new ItemStatus('needed');
	static Collected = new ItemStatus('collected');
	static Acquired = new ItemStatus('acquired');

	constructor(value) {
		this.value = value;
	}

	equals(status) {
		return this.value === status;
	}
}

export default ItemStatus;