import React from 'react';

import ErrorMessage from '../error-message/ErrorMessage';
import { LanguageManager } from '../../app';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			hasError: false
		};
	}

	static getDerivedStateFromError(error) {
		console.log(error);
		return {
			hasError: true
		};
	}

	componentDidCatch(error, info) {
		console.log(error, info);
	}

	render() {
		if (this.state.hasError) {
			return (
				<ErrorMessage message={LanguageManager.get('general_error_message')} />
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;