export const languages = {
	en: { display: 'EN', direction: 'ltr' },
	he: { display: 'עב', direction: 'rtl' }
};

export const dictionary = {
	general_error_message: {
		en: 'Something went wrong',
		he: 'משהו לא תקין'
	},
	change_language_error: {
		en: 'Invalid language',
		he: 'שפה לא קיימת'
	},
	item_title_label: {
		en: 'Milk / Carrot / Soap',
		he: 'חלב / גזר / סבון'
	},
	item_amount_label: {
		en: 'Amout',
		he: 'כמות'
	},
	item_add_button_label: {
		en: 'Add',
		he: 'הוסף'
	},
	item_missing_title: {
		en: 'Please fill item name',
		he: 'נא למלא שם מוצר'
	},
	in_cart_title: {
		en: 'Collected:',
		he: 'נאסף:'
	},
	in_cart_button: {
		en: 'Done',
		he: 'סיים'
	},
	suggestions_title: {
		en: 'Suggestions:',
		he: 'הצעות:'
	}
};