import styled from 'styled-components';
import { ItemStatus } from '../../app/items';

const ItemBox = styled.div`
	display: block;
	margin-bottom: 2pt;
	padding: 6pt;
	width: 100%;
	background-color: var(--a-shadow-color);
	border-bottom: solid 2pt var(--c-color);
`;

const ItemLabel = styled.label`
	cursor: pointer;
`;

const ItemText = styled.div`
	cursor: pointer;
`;

function ItemWithCheckbox(props) {
	return (<ItemLabel>
		<input type="checkbox" onChange={event => props.handleChange(event)} value={props.index} checked={ItemStatus.Collected.equals(props.item.status)} name={props.item.id} />
		{props.children}
	</ItemLabel>);
}

function ItemWithOutCheckbox(props) {
	return (<ItemText onClick={event => props.handleLabelClicked(props.item, event)}>
		{props.children}
	</ItemText>);
}

function Item(props) {
	const ItemWrapper = (ItemStatus.Acquired.equals(props.item.status)) ? ItemWithOutCheckbox : ItemWithCheckbox;

	return (<ItemBox>
		<ItemWrapper {...props}>
			<span>&nbsp;</span>
			<span>{props.item.amount}</span>
			<span>&nbsp;</span>
			<span>{props.item.title}</span>
		</ItemWrapper>
	</ItemBox>);
}

export default Item;