import ObserversManager from './ObserversManager';

class Controller {
	constructor() {
		this.observersManager = new ObserversManager();
	}

	attach(observer) {
		this.observersManager.attach(observer);
	}

	detach(observerToDetach) {
		this.observersManager.detach(observerToDetach);
	}
}

export default Controller;