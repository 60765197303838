import styled from 'styled-components';
import { LanguageManager } from '../../app';

const Box = styled.div`
	display: grid;
	grid-template-columns: 75% 25%;
	align-items: center;
`;

const Button = styled.button`
	border: 0;
	background: var(--c-shadow-color);
	height: fit-content;
`;

function CartHeadLine(props) {
	if (!props.show) {
		return null;
	}

	return (<Box>
		<h2>{LanguageManager.obtain('in_cart_title')}</h2>
		{props.handleDoneButtenClicked &&
			<Button onClick={event => props.handleDoneButtenClicked(event)}>{LanguageManager.obtain('in_cart_button')}</Button>
		}
	</Box>);
}

export default CartHeadLine;