import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { LanguageManager, ItemsController, ItemStatus } from '../../app';
import ObserverAdapter from '../../core/ObserverAdapter';

const Box = styled.div`
	margin: 6pt 0;
	display: inline-grid;
	grid-template-columns: min-content auto;
	grid-column-gap: 2pt;
	width: 100%;
`;

const Label = styled.span`
	font-weight: bold;
`;

const List = styled.ul`
	display: block;
	list-style-type: none;
	margin-block-start: 0;
	padding-inline-start: 0;
	margin: 0;
	overflow-x: scroll;
	overflow-y: hidden;
	width: 100%;
	-ms-overflow-style: none;
	scrollbar-width: none;
	::-webkit-scrollbar {
		display: none;
	}
`;

const Item = styled.li`
	display: inline;
	white-space:nowrap;
	margin: 2pt;
	margin-top: 0;
	cursor: pointer;

	:not(:last-child):after {
		content: ',';
	}

	:last-child:after {
		content: '.';
	}
`;

function Suggestions(props) {
	function getItems() {
		return ItemsController.getItemsByStatus(ItemStatus.Acquired);
	}

	const [items, setItems] = useState(getItems());

	useEffect(() => {
		const handleSuggestionsUpdat = new ObserverAdapter(() => setItems(getItems()));

		ItemsController.attach(handleSuggestionsUpdat);
		return function cleanup() {
			ItemsController.detach(handleSuggestionsUpdat);
		};
	});

	if (items.length === 0) {
		return null;
	}

	return (<Box>
		<Label>{LanguageManager.obtain('suggestions_title')}</Label>
		<List>
			{items.map((item, index) => <Item key={item.id} onClick={event => ItemsController.invertItemStatus(item.id)}>
				{item.title}
			</Item>)}
		</List>
	</Box>);
}

export default Suggestions;