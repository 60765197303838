import React, { Fragment } from 'react';
import styled from 'styled-components';
import { ItemsController, ItemStatus } from '../../app/items';
import ObserverAdapter from '../../core/ObserverAdapter';
import CartHeadLine from './CartHeadLine';
import Item from './Item';

const List = styled.div`
	width: 100%;
`;

const ItemStatusScore = {
	[ItemStatus.Needed.value]: 1,
	[ItemStatus.Collected.value]: 3,
	[ItemStatus.Acquired.value]: 2
};

class ItemList extends React.Component {
	constructor(props) {
		super(props);

		this.observerAdapter = new ObserverAdapter(this.update.bind(this));

		this.state = {
			items: ItemsController.getItems()
		};
	}

	componentDidMount() {
		ItemsController.attach(this.observerAdapter);
	}

	componentWillUnmount() {
		ItemsController.detach(this.observerAdapter);
	}

	update(event) {
		this.setState({ items: event.data });
	}

	handleChange(event) {
		ItemsController.invertItemStatus(parseInt(event.target.name));
	}

	isCartHeadLineNeeded(item, previousItem) {
		if (ItemStatus.Collected.equals(item.status)) {
			if (!previousItem) {
				return true;
			}

			if (!ItemStatus.Collected.equals(previousItem.status)) {
				return true;
			}
		}

		return false;
	}

	handleDoneButtenClicked(event) {
		ItemsController.acquireCollectedItems();
	}

	handleLabelClicked(item) {
		ItemsController.invertItemStatus(item.id);
	}

	sortItems(itemA, itemB) {
		let scoreA = ItemStatusScore[itemA.status] || 0;
		let scoreB = ItemStatusScore[itemB.status] || 0;

		return scoreA - scoreB;
	}

	filterItems(item) {
		return !ItemStatus.Acquired.equals(item.status) || ItemsController.isFilterActive();
	}

	render() {
		let items = this.state.items.filter(item => this.filterItems(item)).sort((a, b) => this.sortItems(a, b));

		return <List>
			{items.map((item, index) =>
				<Fragment key={index}>
					<CartHeadLine show={this.isCartHeadLineNeeded(item, items[index - 1])} handleDoneButtenClicked={event => this.handleDoneButtenClicked(event)} />
					<Item item={item} index={index} handleChange={this.handleChange} handleLabelClicked={this.handleLabelClicked.bind(this)} />
				</Fragment>
			)}
		</List>;
	}
}

export default ItemList;