import React, { useEffect, useState } from 'react';

const getDarkMedia = () => window.matchMedia('(prefers-color-scheme: dark)');
const getNewThemeColor = () => window.getComputedStyle(document.body, "").getPropertyValue("background-color");
const getThemeName = (isDark) => isDark ? 'dark' : 'light';

const ThemeContext = React.createContext({
	theme: '',
	toggleTheme: () => { }
});

function ThemeProvider(props) {
	const [theme, setTheme] = useState(getThemeName());

	const onThemeChange = (theme) => {
		setTheme(theme);

		document.body.className = theme;

		document.querySelector("meta[name='theme-color']").content = getNewThemeColor();
	}

	const toggleTheme = () => {
		onThemeChange(getThemeName(theme !== 'dark'))
	}

	useEffect(() => {
		const eventListener = event => onThemeChange(getThemeName(event.matches));

		getDarkMedia().addEventListener('change', eventListener);

		onThemeChange(getThemeName(getDarkMedia().matches));

		return () => {
			getDarkMedia().removeEventListener('change', eventListener);
		}
	}, []);

	return (
		<ThemeContext.Provider value={{ theme, toggleTheme }}>
			{props.children}
		</ThemeContext.Provider>
	);
}

export function withTheme(WrappedComponent) {
	return (props) => (
		<ThemeProvider>
			<WrappedComponent {...props} />
		</ThemeProvider>
	);
}

export default ThemeContext;
