class ObserverAdapter {
	constructor(updateCallback) {
		if (typeof updateCallback !== 'function') {
			throw new Error('Can not initiate ObserverAdapter');
		}

		this.updateCallback = updateCallback;
	}

	update(event) {
		this.updateCallback(event);
	}
}

export default ObserverAdapter;