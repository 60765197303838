import { Fragment } from 'react';

import AddItem from '../add-item/AddItem';
import ItemList from '../item-list/ItemList';
import Suggestions from '../suggestions/Suggestions';

import '../../style-sheets/review.scss';

function Review() {
	return (
		<Fragment>
			<AddItem />
			<Suggestions />
			<ItemList />
		</Fragment >
	);
}

export default Review;