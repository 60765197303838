import { useState } from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route
} from 'react-router-dom';
import styled from 'styled-components';

import { withTheme } from '../theme/Theme';
import Review from '../review/Review';
import ErrorBoundary from '../error-boundary/ErrorBoundary';
import { LanguageManager } from '../../app';

import '../../style-sheets/index.scss';

const Container = styled.div`
	width: 90%;
	margin: auto;
	padding-top: 1vh;
`;

const LanguageList = styled.div`
	padding: 10pt 0;
	direction: ltr;
	display: flex;
	justify-content: space-evenly;
`;

const LanguageItem = styled.div`
	cursor: pointer;
`;

function Main() {
	const [lang, setLang] = useState(LanguageManager.getLanguage());

	LanguageManager.setLanguage(lang);

	function handleClick(event) {
		setLang(event.target.getAttribute('data-lang'));
	}

	return (
		<Router>
			<Container style={{ direction: LanguageManager.getDirection() }}>
				<ErrorBoundary>
					<Switch>
						<Route strict exact path="/">
							<Review />
						</Route>
						<Route path="*" render={() => <h1>404</h1>} />
					</Switch>
				</ErrorBoundary>
			</Container>
			<LanguageList>
				{LanguageManager.getLanguages().map((lang, index) =>
					<LanguageItem key={index} data-lang={lang} onClick={handleClick}>{LanguageManager.getDisplay(lang)}</LanguageItem>
				)}
			</LanguageList>
		</Router>
	);
}

export default withTheme(Main);
