import ObserverAdapter from './ObserverAdapter';

class ObserversManager {
	constructor() {
		this.observers = [];
	}

	attach(observer) {
		if (!(observer instanceof ObserverAdapter)) {
			throw new Error('Can not add observer without adapter');
		}

		this.observers.push(observer);
	}

	detach(observerToDetach) {
		this.observers = this.observers.filter((observer) => {
			return observer !== observerToDetach;
		});
	}

	notify(event) {
		this.observers.forEach(observer => {
			observer.update(event);
		});
	}
}

export default ObserversManager;