import styled from 'styled-components';

const Box = styled.div`
	width: 100%;
	color: var(--error-color);
`;

function ErrorMessage(props) {
	return (
		<Box><h1>{props.message}</h1></Box>
	);
}

export default ErrorMessage;